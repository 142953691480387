import React, { useRef, useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { currentUserState } from "../../state/atoms";
import {
    fetchBookmarksFolders,
    fetchData,
    toggleBookmarkFolder,
    toggleBriefBookmark,
    toggleCampaignBookmark,
    toggleMasterClassBookmark,
    checkBookmarkStatus,
    fetchMetaData
} from "../../helpers/functions";
import { IfaceAllNewestResponse } from "../../helpers/interfaces";

// Styles
import modalStyle from "../../styles/components/Modal.module.scss";

// Images
import Close from "../../images/ui-plus-close.svg";
import { setUser } from "@sentry/nextjs";

type Props = {
    post?: any;
    id: any;
    slug: any;
    objectType: any;
    setShowPopup: any;
    setIsBookmarked: any;
    isBookmarked: boolean;
    bookmarkID?: any;
    isUnsortedDefault: boolean;
    setIsUnsortedDefault: any;
    newBookmark: any;
    setNewBookmark: any;
    isNewBookmark: any;
    setIsNewBookmark: any;
};

const FolderOption = (props: any) => {
    const folder = props.folder;
    const article_id = props.article_id;
    let checked = props.checked;

    const toggleActiveBookmarks = () => {
        let toggledBookmarks = [...props.toggledBookmarks];
        if (toggledBookmarks.indexOf(folder.id) === -1) {
            toggledBookmarks.push(folder.id);
        } else {
            toggledBookmarks.splice(toggledBookmarks.indexOf(folder.id), 1);
        }
        if (props.isUnsortedDefault) {
            props.setIsUnsortedDefault(false);
        }
        if (props.isNewBookmark && folder.folder_name.toLowerCase() !== 'unsorted') {
            const unsortedFolder = document.getElementById('unsorted-option-' + article_id);
            if (unsortedFolder) {
                toggledBookmarks = [folder.id];
                props.setIsNewBookmark(false);
            }
        }
        props.setToggledBookmarks(toggledBookmarks);
    };

    if (folder && folder.folder_name.toLowerCase() === 'unsorted' && props.isUnsortedDefault && !checked) {
        const matchedContent = folder.folder_contents.filter((content: any) => {
            return content.content_id === article_id;
        });

        if (matchedContent.length === 0) {
            toggleActiveBookmarks();
            checked = true;
        }
    }

    return (
        <li className={`${checked ? modalStyle.checked : ''}`} key={'folder-option-' + folder.id}>
            <label id={`unsorted-option-${article_id}`} className={`folder-option folder-option-${folder.id}`}
                htmlFor={`bookmark-${folder.id}`} data-id={folder.id}>{folder.folder_name}</label>
            <div className={modalStyle.inputContainer}>
                <div className={'text-container'}>
                    <p className={`${modalStyle.title}`}>{folder.folder_name}</p>
                    <p className={`${modalStyle.count}`}>{folder.folder_contents_count} {folder.folder_contents_count !== 1 ? ' Items' : ' Item'}</p>
                </div>
                <input id={`bookmark-${folder.id}`} type={'checkbox'} name={'folders'} value={folder.folder_name} onChange={(e) => {
                }} onClick={(e: any) => {
                    if (e.target.checked) {
                        props.setShowFolderWarning(false);
                    }
                    toggleActiveBookmarks();
                }} checked={checked} />
            </div>
        </li>
    )
}

const CreativeBookmarkPopup = ({ post, id, slug, objectType, setShowPopup, setIsBookmarked, isBookmarked, bookmarkID, isUnsortedDefault, setIsUnsortedDefault, newBookmark, setNewBookmark, isNewBookmark, setIsNewBookmark }: Props) => {
    const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
    let userInfo = { ...currentUser };
    const [activeFolders, setActiveFolders] = useState<any>([]);
    const [bookmarkFolders, setBookmarkFolders] = useState<any>([]);
    const [toggledBookmarks, setToggledBookmarks] = useState<any>([]);
    const [showFolderWarning, setShowFolderWarning] = useState<boolean>(false);
    const [userBookmarkID, setUserBookmarkID] = useState<any>(bookmarkID ? bookmarkID : null);

    useEffect(() => {
        if(bookmarkID && userBookmarkID !== bookmarkID) {
            setUserBookmarkID(bookmarkID);
        }
    }, [bookmarkID]);

    const getFoldersData = async () => {
        if (userInfo.id !== null) {
            const newestPostsApi: IfaceAllNewestResponse = await fetchBookmarksFolders(userInfo.id);
            if (bookmarkFolders !== newestPostsApi.res.data) {
                setBookmarkFolders(newestPostsApi.res.data);
            }
        }
    };

    useEffect(() => {
        getFoldersData(); // get initial data
    }, [currentUser]);

    const getArticlesData = async () => {
        if (userInfo.id !== null) {
            let endpoint: string = 'creative-effectiveness/';
            if (objectType === 'brief' || objectType === 'briefs') {
                endpoint += 'briefs/' + slug;
            } else if (objectType === 'campaign' || objectType === 'campaigns') {
                endpoint += 'campaigns/' + slug;
            } else {
                endpoint += 'master-classes/' + slug;
            }
            const articleData = await fetchData(endpoint);
            if (articleData.loaded && !articleData.error) {
                // if (articleData.res.data['user_bookmarked_id'] && bookmarkID !== articleData.res.data['user_bookmarked_id']) {
                //     setBookmarkID(articleData.res.data['user_bookmarked_id']);
                // }
                if (articleData.res.data['user_bookmark_folders'] && activeFolders.length !== articleData.res.data['user_bookmark_folders'].length) {
                    setActiveFolders(articleData.res.data['user_bookmark_folders'])
                }
            }
        }
    };

    const toggleSingleBookmarkFolder = async ({userID, folderID, bookmarkID}: any) => {
        const response = await toggleBookmarkFolder(userID, folderID, bookmarkID);
        console.log('userID', userID);
        console.log('folderID', folderID);
        console.log('bookmarkID', bookmarkID);
        console.log('folder toggled', response);
    };

    const toggleAllBookmarkFolders = () => {
        if (toggledBookmarks.length > 0) {
            toggledBookmarks.forEach((id: any) => {
                if (userInfo.id !== null && userBookmarkID) {
                    // response = await toggleBookmarkFolder(userInfo.id, id, userBookmarkID);
                    toggleSingleBookmarkFolder({
                        userID:userInfo.id,
                        folderID: id,
                        bookmarkID: userBookmarkID
                    });
                }
            })
        }
        setToggledBookmarks([]);
        setShowPopup(false);
    }

    const deleteBookmarked = async () => {
        let response: any = {
            loaded: false,
            error: true,
        };
        if (objectType === 'brief' || objectType === 'briefs') {
            response = await toggleBriefBookmark(id);
        } else if (objectType === 'campaign' || objectType === 'campaigns') {
            response = await toggleCampaignBookmark(id);
        } else if (objectType === 'masterclass' || objectType === 'master_classes') {
            response = await toggleMasterClassBookmark(id);
        }

        if (response.loaded && !response.error) {
            isBookmarked ? setIsBookmarked(false) : setIsBookmarked(true);
            setShowPopup(false);
        }
    };

    const getBookmarkStatus = async (objectType: any, id: any) => {
        const res: any = await checkBookmarkStatus(objectType, id);
        if (!res.error && res.res.data) {
            if (Object.keys(res.res.data).length > 0) {
                const data: any = res.res.data;
                // if (data[id] !== null && data[id] !== undefined) {
                //     setIsBookmarked(data[id]);
                // }
            }
        }
    }

    useEffect(() => {
        if(objectType && id) {
            setTimeout(() => {
                getBookmarkStatus(objectType, id);
            }, 1000);
        }
    }, []);

    useEffect(() => {
        // getArticlesData();
    }, [bookmarkID, isBookmarked]);

    // Get post meta data
    const [metaData, setMetaData] = useState<any>(null);
    async function getMetaData() {
        let params: any = {}
        const key: string = objectType + 's';
        params[key] = [id];
        const pageMetaData: any = await fetchMetaData(params);
        if(!pageMetaData.error && pageMetaData.res && pageMetaData.res.data && pageMetaData.res.data.length > 0) {
            const res: any = pageMetaData.res.data[0];
            setMetaData(res);
            setUserBookmarkID(res.user_bookmarked_id);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            if(!metaData) {
                getMetaData();
            }
        }, 1000);
    }, []);

    return (
        <div className={`${modalStyle.dialogLayer}`}>
            <div className={`${modalStyle.dialogLayerInner}`}>
                <div className={`${modalStyle.background}`} onClick={() => {
                    setShowPopup(false)
                }}></div>
                <div role={'dialog'} id={'folder-dialog'} aria-modal={true} className={`${modalStyle.dialogBox} ${modalStyle.folderEditContainer}`}>
                    <div className={`${modalStyle.headerContainer}`}>
                        <h2>Select Bookmark Folder</h2>
                        <button className={`${modalStyle.closeBtn}`} onClick={() => {
                            setShowPopup(false);
                        }}>
                            <span>Close</span>
                            <Close />
                        </button>
                    </div>
                    <form id={'folder-form-' + id}>
                        <ul>
                            {bookmarkFolders?.map((folder: any) => {
                                let checked = false;
                                const matchedContent = folder.folder_contents.filter((content: any) => {
                                    return content.content_id === id;
                                });
                                if ((matchedContent.length > 0 && toggledBookmarks.indexOf(folder.id) === -1) || matchedContent.length === 0 && toggledBookmarks.indexOf(folder.id) > -1) {
                                    checked = true;
                                }
                                return (
                                    <FolderOption
                                        article_id={id}
                                        folder={folder}
                                        key={'folder-option-' + folder.id}
                                        toggledBookmarks={toggledBookmarks}
                                        setToggledBookmarks={setToggledBookmarks}
                                        checked={checked}
                                        isUnsortedDefault={isUnsortedDefault}
                                        setIsUnsortedDefault={setIsUnsortedDefault}
                                        isNewBookmark={isNewBookmark}
                                        setIsNewBookmark={setIsNewBookmark}
                                        setShowFolderWarning={setShowFolderWarning}
                                    />
                                )
                            })}
                        </ul>
                        {showFolderWarning && <p className={modalStyle.folderWarning}>Please select a folder</p>}
                        <button type={'submit'} className={'btn'} onClick={(e) => {
                            e.preventDefault();
                            let hasSelectedFolders: boolean = false;

                            const form = document.getElementById('folder-form-' + id);
                            if (form) {
                                const folderOptions = form.querySelectorAll('input[type="checkbox"]');
                                if (folderOptions) {
                                    folderOptions.forEach((option: any) => {
                                        if (option.checked) {
                                            hasSelectedFolders = true;
                                        }
                                    })
                                }
                            }

                            if (hasSelectedFolders) {
                                toggleAllBookmarkFolders();
                                setNewBookmark(false);
                            } else {
                                setShowFolderWarning(true);
                            }
                        }}>Save</button>
                    </form>
                    <div className={modalStyle.buttonContainer}>
                        <button className={`${modalStyle.cancel}`} onClick={deleteBookmarked}>Delete Bookmark</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreativeBookmarkPopup;
