import React, { useRef, useState, useEffect } from "react";
import BookmarkPopup from "./BookmarkPopup";
import CreativeBookmarkPopup from "./CreativeBoomarkPopup";

// Styles
import styles from "styles/components/CreativeBanner.module.scss";

// Images
import IconBookmark from "../../images/icon-bookmark.svg";

import { createPortal } from "react-dom";
import { IPageApi } from "../../helpers/interfaces";
import { fetchData, fetchMetaData, toggleBriefBookmark, toggleCampaignBookmark, toggleMasterClassBookmark, checkBookmarkStatus } from "../../helpers/functions";

const Modal = (props: any) => {
    const [container] = React.useState(() => {
        // This will be executed only on the initial render
        // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
        return document.createElement('div');
    });

    React.useEffect(() => {
        container.classList.add('root-protal')
        document.body.appendChild(container)
        return () => {
            document.body.removeChild(container)
        }
    }, [])

    return createPortal(
        props.children,
        container,
    );
}

type Props = {
    post?: any;
    id?: number;
    slug?: any;
    objectType?: any;
    isBookmarked?: any;
    additionalClasses?: any;
};

function BookmarkButton({ post, id, slug, objectType, additionalClasses }: Props) {
    const [showPopup, setShowPopup] = useState(false);
    // const [post, setPost] = useState<any>(null);
    const [isBookmarked, setIsBookmarked] = useState<boolean>(false);
    const [isUnsortedDefault, setIsUnsortedDefault] = useState(false); const [isNewBookmark, setIsNewBookmark] = useState(false);
    const [newBookmark, setNewBookmark] = useState(false);

    const [metaData, setMetaData] = useState<any>(null);

    async function getMetaData() {
        let params: any = {}
        let key: any = post.res.data.object_type ? post.res.data.object_type.toLowerCase() + 's' : null;
        if (key === null && objectType) {
            key = objectType + 's';
        }
        if (key !== null) {
            params[key] = [post.res.data.id];
            const pageMetaData: any = await fetchMetaData(params);
            if (!pageMetaData.error && pageMetaData.res && pageMetaData.res.data && pageMetaData.res.data.length > 0) {
                const res: any = pageMetaData.res.data[0];
                setMetaData(res);
                if (res && res.user_bookmarked) {
                    setIsBookmarked(true);
                }
            }
        }
    }

    useEffect(() => {
        if (!metaData) {
            getMetaData();
        }
    }, []);

    const handleBookmarked = async () => {
        let response: any = {
            loaded: false,
            error: true,
        };
        if (objectType === 'brief' || objectType === 'briefs') {
            response = await toggleBriefBookmark(id);
        } else if (objectType === 'campaign' || objectType === 'campaigns') {
            response = await toggleCampaignBookmark(id);
        } else if (objectType === 'masterclass' || objectType === 'master_classes') {
            response = await toggleMasterClassBookmark(id);
        }

        if (response.loaded && !response.error) {
            isBookmarked ? setIsBookmarked(false) : setIsBookmarked(true);
            setShowPopup(true);
        }
    };

    return (
        <>
            <span
                className={`${styles.bookmarkContainer} ${additionalClasses ? additionalClasses : ''}`}>
                <button
                    aria-label="Bookmark"
                    className={`${styles.actionButton} ${isBookmarked ? `${styles.isBookmarked} isBookmarked` : ''}`}
                    onClick={() => {
                        if (!isBookmarked) {
                            handleBookmarked();
                        }
                        setShowPopup(true);
                    }}>
                    <IconBookmark />
                    <span>Save</span>
                </button>
            </span>
            {showPopup &&
                <Modal>
                    <CreativeBookmarkPopup
                        post={post ? post : null}
                        id={id}
                        slug={slug}
                        objectType={objectType}
                        bookmarkID={post && !post.error && post?.res.data.user_bookmarked_id ? post?.res.data.user_bookmarked_id : null}
                        setShowPopup={setShowPopup}
                        setIsBookmarked={setIsBookmarked}
                        isBookmarked={isBookmarked}
                        isUnsortedDefault={isUnsortedDefault}
                        setIsUnsortedDefault={setIsUnsortedDefault}
                        newBookmark={newBookmark}
                        setNewBookmark={setNewBookmark}
                        isNewBookmark={isNewBookmark}
                        setIsNewBookmark={setIsNewBookmark}
                    />
                </Modal>
            }
        </>
    )
}

export default BookmarkButton;
